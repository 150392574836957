import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const Sm = styled.button`
  width: fit-content;
  background: #5ca9fb;
  position: relative;
  bottom: 30px;
  height: 50px;
  font-size: 15px;
  border-radius: 0;
  border: none;
  color: whitesmoke;
  padding: 10px;
`;
const SeeMore = ({ children, linkTo }) => {
  let history = useHistory();

  const handleClick = () => {
    history.push(`/${linkTo}`);
  };
  return <Sm onClick={handleClick}>{children}</Sm>;
};

export default SeeMore;
