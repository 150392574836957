import React from "react";
import classes from "./Card.module.css";

function Card({ children }) {
  return (
    <div className={classes.card} style={{ width: "100%" }}>
      {children}
    </div>
  );
}

export default Card;
