import React, { useState, useEffect, Suspense } from "react";
import { Navigation } from "./components/navigation";

// import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
// import { Gallery } from "./components/gallery";
// import { Testimonials } from "./components/testimonials";
// import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import Card from "./Card";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import firstOverallImage from "./assets/real/2real.jpg";
import secondOverallImage from "./assets/real/4.jpg";
import thirdOverallImage from "./assets/real/3.jpg";
import fourthOverallImage from "./assets/real/1.jpg";
import recruitmentImage from "./assets/realServices/recruitment.png";
import backgroundCheckImage from "./assets/realServices/backgroundCheck.png";
import marketingImage from "./assets/realServices/marketing.png";
import researchImage from "./assets/realServices/research.png";

import { Fragment } from "react";

// import { Features } from "./components/features";

import Header2 from "./components/Header2";
import Loading from "./components/UI/Loading";

// import "bootstrap/dist/css/bootstrap.min.css";

const Outsource = React.lazy(() =>
  import("./components/ServicesContent/Outsource")
);
const BackgroundCheck = React.lazy(() =>
  import("./components/ServicesContent/BackgroundCheck")
);
const Research = React.lazy(() =>
  import("./components/ServicesContent/Research")
);
const GlobalMarketing = React.lazy(() =>
  import("./components/ServicesContent/GlobalMarketing")
);
const Marketing = React.lazy(() =>
  import("./components/ServicesContent/Marketing")
);
const Recruitment = React.lazy(() =>
  import("./components/ServicesContent/Recruitment")
);

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  const images = [
    firstOverallImage,
    secondOverallImage,

    thirdOverallImage,
    fourthOverallImage,
  ];

  // useEffect(() => {
  //   cacheImages(images);
  // });

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Router>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path="/HR Outsourcing">
              <Navigation />
              <Outsource />
              <Contact data={landingPageData.Contact} />
            </Route>
            <Route path="/Background Check">
              <Navigation />
              <BackgroundCheck image={backgroundCheckImage} />
              <Contact data={landingPageData.Contact} />
            </Route>
            <Route path="/Research">
              <Navigation />
              <Research image={researchImage} />
              <Contact data={landingPageData.Contact} />
            </Route>
            <Route path="/General Business Consulting">
              <Navigation />
              <GlobalMarketing image={fourthOverallImage} />
              <Contact data={landingPageData.Contact} />
            </Route>
            <Route path="/Marketing">
              <Navigation />
              <Marketing image={marketingImage} />
              <Contact data={landingPageData.Contact} />
            </Route>
            <Route path="/Recruitment">
              <Navigation />
              <Recruitment image={recruitmentImage} />
              <Contact data={landingPageData.Contact} />
            </Route>
            <Route path="/">
              <Navigation />

              <Card>
                <Fragment>
                  <Header2 images={images} />
                  <Services data={landingPageData.Services} />

                  <About data={landingPageData.About} />

                  <Contact data={landingPageData.Contact} />
                </Fragment>
              </Card>
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
};

export default App;
