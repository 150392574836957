import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import Hamburger from "hamburger-react";
import { useState } from "react";
import "./navigation.css";
import { NavHashLink } from "react-router-hash-link";

import { Link } from "react-router-dom";

export const Navigation = (props) => {
  const servicesArray = [
    "Recruitment",
    "General Business Consulting",
    "Research",
    "Marketing",
    "Background Check",
    "HR Outsourcing",
  ];
  const [showDrop, setShowDrop] = useState(true);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            style={{ border: "none", position: "fixed", top: 1, right: 0 }}
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <Hamburger />
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <img
              src="img/logo.png"
              className="img responsive nav-img"
              alt="NOM LEGACY"
            />
          </a>{" "}
          <NavHashLink to="/#page-top">
            <img
              src="img/logo.png"
              className="img responsive nav-img"
              alt="NOM LEGACY"
            />
          </NavHashLink>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li className="nav-item">
              {/* <a href="#header" className="page-scroll">
                Home
              </a> */}
              <NavHashLink to="/#header">Home</NavHashLink>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                onClick={() => setShowDrop((prevState) => !prevState)}
              >
                Services {showDrop ? <ArrowDropDown /> : <ArrowDropUp />}
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {servicesArray.map((service, id) => (
                  <li key={id}>
                    {" "}
                    <Link className="dropdown-item" to={service}>
                      {service}
                    </Link>{" "}
                  </li>
                ))}
              </ul>
            </li>
            <li className="nav-item">
              <NavHashLink to="/#about">About</NavHashLink>
            </li>

            {/* <li className="nav-item">
              <a href="#features" className="page-scroll">
                Features
              </a>
            </li> */}
            {/* <li>
              <a href="#portfolio" className="page-scroll">
                Gallery
              </a>
            </li> */}
            {/* <li>
              <a href="#testimonials" className="page-scroll">
                Testimonials
              </a>
            </li> */}
            {/* <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li> */}
            <li className="nav-item">
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
