import React from "react";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Header2 = ({ images }) => {
  return (
    <div
      id="header"
      style={{
        position: "relative",
        top: "80px",
      }}
    >
      <Carousel
        showStatus={false}
        dynamicHeight="50vh"
        autoPlay={true}
        showThumbs={false}
        useKeyboardArrows
      >
        {images.map((image, index) => (
          <div key={index}>
            <img style={{ maxHeight: "80vh" }} src={image} alt="banner image" />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Header2;
