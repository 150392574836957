import SeeMore from "../SeeMore";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>We offer the best you can get of the following services</p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  {/* <i className={d.icon}></i> */}
                  <img
                    className={`img-responsive ${d.icon}`}
                    style={{
                      maxWidth: 100,
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                    src={d.icon}
                    alt={d.name}
                  />
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                  <SeeMore linkTo={d.name}>Details here</SeeMore>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
